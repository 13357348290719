export default class TogglerMenu {

    toggler = null;

    constructor(element) {
        this.setToggler(element);
        this.remove_oversflow_hidden();
        this.initEvents();
    }

    setToggler(element) {
        this.toggler = jQuery(element);
    }

    getToggler() {
        return this.toggler;
    }

    initEvents() {
        const instance = this;

        instance.getToggler().on("click", function () {
            instance.setToggler(this);
            instance.remove_oversflow_hidden();
        });
    }

    remove_oversflow_hidden() {
        if (this.getToggler().attr("aria-expanded") == "true") {
            jQuery("body").addClass("overflow-hidden");
        }
        else {
            jQuery("body").removeClass("overflow-hidden");
        }
    }

}