export default class ReturnTop {

    #classToHidden = "";
    #btn = null;
    #scrollPosition = 0;
    #startScrollPixelVisible = 0;

    constructor(btn, classToHidden = "d-none", startScrollPixelVisible = 0) {
        this.#btn = btn;
        this.#classToHidden = classToHidden;
        this.#startScrollPixelVisible = startScrollPixelVisible;
        this.#refreshVisibility();
        this.#events();
    }

    #setScrollPosition() {
        this.#scrollPosition = window.scrollY;
    }

    #events() {
        window.addEventListener("scroll", () => {
            this.#refreshVisibility();
        });
    }

    #refreshVisibility() {
        this.#setScrollPosition();
        this.#isVisibleOrHidden();
    }

    #addClass() {
        this.#btn.classList.add(this.#classToHidden);
    }

    #removeClass() {
        this.#btn.classList.remove(this.#classToHidden);
    }

    #isVisibleOrHidden() {
        if (this.#scrollPosition > this.#startScrollPixelVisible) {
            this.#removeClass(this.#btn);
        } else {
            this.#addClass(this.#btn);
        }
    }
}