export default class OnScroll {

    element = null;

    constructor(pElement) {
        this.element = pElement;
        this.addClasses();
        this.Events();
    }

    Events() {
        jQuery(window).scroll(() => {
            this.addClasses();
            // a chaque scroll on ajoute une animation de transition avec une attente de 3sec
            
        });
    }

    addClasses() {
        if (jQuery(window).scrollTop() > 1) {
            this.element.addClass("on-scroll");
            this.element.removeClass("no-scroll");
        } else {
            this.element.removeClass("on-scroll");
            this.element.addClass("no-scroll");
        }
    }
}